import request from './shouye_request'
import ucenter_request from './ucenterrequest'
import qs from 'qs'
import edu_request from './request'
// 封装接口数据
export function getH5IndexDatas(params) {
  return request({
    url: '/index/index/getH5Index',
    method: 'GET',
    params
  })
}
export function getzhibo(params) {
  return request({
    url: '/edu/lesson/getLiveList',
    method: 'GET',
    params
  })
}
// 获取用户信息
export function getUserInfo(params) {
  return ucenter_request({
    url: '/personal/myinfo/getUserInfo',
    method: 'GET',
    params
  })
}
// 获取加好友二维码
export function getWxworkQrcode(params) {
  return ucenter_request({
    url: '/ucenter/user/getWxworkQrcode',
    method: 'GET',
    params
  })
}
// 获取AD图
export function getPmPlanList(params) {
  return ucenter_request({
    url: '/ucenter/user/getPmPlanList',
    method: 'GET',
    params
  })
}
export function getWxUrl(params) {
  return ucenter_request({
    url: 'ucenter/miniprogram/getMiniUrlLink',
    method: 'POST',
    data: qs.stringify(params)
  })
}

// 获取是否显示直播、课程
export function canShowForMp(params) {
  return edu_request({
    url: '/test/wechatapplet/applet',
    method: 'GET',
    params
  })
}
