<template>
  <div>
    <div v-show="!wx" class="div_title"> {{ $route.query.type != 2 ?'':'扫码加老师' }}</div>
    <div v-loading="showloading" style="margin-top: 120px">
      <div v-show="showPage">
        <div class="div_content">
          <p v-show="group_name" class="p_title">{{ group_name }}</p>
          <div v-show="gropu_people_name" class="sub_content">
            <img src="@/assets/group.png" alt="">
            <span>{{ gropu_people_name }}</span>
          </div>
          <div class="div_img">
            <van-image
              class="iv_code"
              width="100%"
              height="100%"
              :show-error="true"
              :src="qrcode_url"
            />
            <div v-show="showContent&&minutes===0 && seconds===0 ||　error" class="overlay">
              <div class="expired-icon"></div>
            </div>
            <p v-show="showContent && minutes===0 && seconds===0　||　error" class="p_img_tip">
              <span>{{ errorTip }}</span>
            </p>
          </div>
          <p v-show="qrcode_tip" class="p_tip">{{ qrcode_tip }}</p>
        </div>
        <p v-show="minutes !==0 || seconds !== 0" class="p_hour">
          <span>0{{ minutes }}:{{ seconds<10 ? '0':'' }}{{ seconds }} 后失效</span>
        </p>
        <p v-show="error" class="p_hour">
          {{ errorTip }}
        </p>
        <div v-show="!wx && this.miniProgress" class="div_btn" @click="goMiniProgress">添加好友</div>
      </div>
    </div>
    <!-- 选分类 -->
    <div v-if="showSelCategory" class="sbc_box">
      <div v-if="showSelCategory" class="prop_box">
        <div class="prop_box_top">
          <van-sticky>
            <h1>选择感兴趣的专业</h1>
          </van-sticky>
        </div>
        <div v-for="(v,i) in list" :key="i" class="prop_box_bottom">
          <h2>{{ v.category_name }}</h2>
          <ul>
            <li v-for="(item,index) in v.children" :key="index" :class="old_fid==item.id ? 'itemProfress sel' : 'itemProfress'" @click="selectCat(item.id,item.category_name)">{{ item.category_name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWxworkGroupCode, getAddressCode, getAreaQrcodeForChatGroup, getInterestCode } from '@/api/weixin.js'
import { getWxUrl } from '@/utils/index.js'
import WechatState from '@/utils/isWechatState.js'
import Cookie from 'js-cookie'
import { getCategoryList } from '@/api/infohome.js'

export default {
  data() {
    return {
      showloading: true,
      qrcode_url: '',
      group_name: '',
      minutes: 0,
      seconds: 0,
      gropu_people_name: '',
      qrcode_tip: '',
      error: false,
      showContent: false,
      showPage: false,
      wx: WechatState.isWechat,
      errorTip: '',
      miniProgress: '',
      showSelCategory: false,
      old_fid: Cookie.get('fid') || '',
      list: []
    }
  },
  mounted() {
    this.old_fid = Cookie.get('fid') || ''
    this.categorywFun()
    this.getDate()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 分类接口
    categorywFun() {
      const date = {
        agency_id: Cookie.get('agency_id') || '2'
      }
      getCategoryList(date).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result ? res.result : []
          this.list = result
        }
      })
    },
    selectCat(fid, name) {
      Cookie.set('fid', fid)
      Cookie.set('Fname', name)
      this.old_fid = fid
      this.showSelCategory = false
      this.type4Func()
    },
    getWxUrl() {
      getWxUrl({
        'path': '/pages/qrCode/qrCode',
        'env_version': 'release',
        'query': 'jsonstr=' + encodeURIComponent('qrCodeTitle=' + '添加老师' + '&qrcodeurl=' + this.qrcode_url + '&title=' + this.group_name + '&detail=' + this.qrcode_tip + '&qrCodeBg=' + 'https://newoss.zhulong.com/forum/202305/05/9/105809m1aqm3auxe83vlnf.png' + '&from=' + 'h5_new_people'),
        'auth_app_id': 'wx828b87bfa158b387',
        'type': 1
      }).then(res => {
        if (res.result) {
          this.miniProgress = res.result
        }
      })
    },
    goMiniProgress() {
      if (this.miniProgress) {
        window.open(this.miniProgress)
      }
    },
    getDate() {
      console.log('this.$route.query.type:' + this.$route.query.type)
      if (this.$route.query.type == 1) {
        this.showPage = false
        document.title = '扫码加群'
        const params = {
          code_id: this.$route.query.id
        }
        console.log('this.$route.query.type:' + this.$route.query.type)

        getWxworkGroupCode(params).then((res) => {
          console.log(res)
          this.showloading = false
          if (res.errNo == 0) {
            if (res.result) {
              window.location.href = res.result.url
            }
          } else {
            this.$toast(res.msg)
            this.errorTip = res.msg
            this.error = true
          }
          this.showContent = true
        })
      } else if (this.$route.query.type == 3) {
        this.showPage = false
        document.title = '扫码加群'
        const params = {
          activity_id: this.$route.query.id
        }
        console.log('this.$route.query.type:' + this.$route.query.type)

        getAreaQrcodeForChatGroup(params).then((res) => {
          console.log(res)
          this.showloading = false
          if (res.errNo == 0) {
            if (res.result) {
              window.location.href = res.result.url
            }
          } else {
            this.$toast(res.msg)
            this.errorTip = res.msg
            this.error = true
          }
          this.showContent = true
        })
      } else if (this.$route.query.type == 2) {
        document.title = '扫码加老师'
        this.showPage = true
        const params = {
          active_id: this.$route.query.id
        }

        getAddressCode(params).then((res) => {
          console.log(res)
          this.showloading = false
          if (res.errNo == 0) {
            this.qrcode_url = res.result.qrcode_url.wework_qr_code
            this.gropu_people_name = res.result.gropu_people_name
            this.group_name = res.result.workname
            this.qrcode_tip = res.result.info
            this.startTime()
          } else {
            this.errorTip = '没有获取到对应地区的老师二维码'
            this.error = true
          }
          this.showContent = true
          this.getWxUrl()
        })
      } else if (this.$route.query.type == 4) {
        this.type4Func()
      }
    },
    type4Func() {
      this.showPage = true
      document.title = '扫码加老师'
      const params = {
        interest_id: this.$route.query.id,
        category_id: this.old_fid
      }
      getInterestCode(params).then((res) => {
        console.log(res)
        this.showloading = false
        if (res.errNo == 0) {
          this.qrcode_url = res.result.qrcode_url.wework_qr_code
          this.gropu_people_name = res.result.gropu_people_name
          this.group_name = res.result.workname
          this.qrcode_tip = res.result.info
          this.startTime()
        } else if (res.errNo == 99) {
          var url = encodeURIComponent(location.href)
          window.open(`${window.location.origin}/ucenter/login?redirect=${url}`, '_self')
        } else if (res.errNo == 101) {
          // 去选分类
          this.showCategory()
        } else {
          this.errorTip = '没有获取到对应老师的二维码'
          this.error = true
        }
        this.getWxUrl()
        this.showContent = true
      })
    },
    // 去选分类
    showCategory() {
      this.showSelCategory = true
    },
    startTime() {
      this.minutes = 3
      this.timer = setInterval(() => {
        if (this.seconds === 0) {
          if (this.minutes === 0) {
            clearInterval(this.timer)
            this.errorTip = '二维码已过期'
            return
          }
          this.minutes--
          this.seconds = 59
        } else {
          this.seconds--
        }
      }, 1000)
    },
    tui() {
      this.$router.go(-1)
    },
    shouye() {
      this.$router.push({
        path: '/banji',
        query: {
          class_id: this.class_id,
          lesson_id: this.lesson_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    },
    wenda() {
      this.$router.push({
        path: '/wenda',
        query: {
          class_id: this.class_id,
          lesson_id: this.lesson_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.div_btn{
  cursor: pointer;
  width: 165px;
  height: 76px;
  margin: 24px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  align-items: center;
  background: #ee2e2e;
  color: white;
  font-size: 30px;
}
.div_title{
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  //box-shadow: -1px -1px 5px rgba(0,0,0,.5);
}
.div_content{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 650px;
  min-height: 800px;
  margin: 0 auto;
  border-radius: 8px;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 12px 2px rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  .div_img{
    width: 460px;
    height: 460px;
    margin-bottom: 60px;
    margin-top: 24px;
    position: relative;
    .iv_code{
      width: 460px;
      height: 460px;
    }
    .p_img_tip{
      position: absolute;
      text-align: center;
      width: 460px;
      height: 88px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      top: 42%;
      color: white;
      span{
        display: inline-block;
        width: 320px;
      }
    }
    .overlay {
      position: absolute;
      top: 0px;
      width: 460px;
      height: 460px;
    }
    .overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .p_title{
    font-size: 40px;
    margin-top: 120px;
    font-weight: 600;
  }
  .sub_content{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 12px;
    margin-right: 2px;
    color: #C0C4CC;
    img {
      width: 40px;
    }
    span{
      display: inline-block;
      margin-left: 4px;
      font-size: 32px;
    }
  }
  .p_tip{
    margin-bottom: 40px;
    font-size: 28px;
    color: #999999;
  }
}
.p_hour{
  text-align: center;
  margin-top: 48px;
  font-size: 30px;
  color: #ee2e2e;
  font-weight: 600;
}
.p_error{
  text-align: center;
  margin-top: 16px;
  font-size: 28px;
  color: #ee2e2e;
}
.sbc_box {
  position: fixed;
  top: 0;
  z-index: 13000;
  width: 100%;
}
.prop_box {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  background: #f2f2f2;
  .prop_box_top {
    width: 100%;
    height: 76px;
    background: white;
    h1 {
      font-size: 0.4rem;
      text-align: center;
      line-height: 76px;
    }
  }
  .prop_box_bottom {
    margin-top: 20px;
    margin-left: 35px;
    margin-bottom: 30px;
    h2 {
      font-size: 0.36rem;
      margin-bottom: 25px;
      color: #454545;
    }
    .itemProfress {
      display: inline-block;
      padding: 22px 20px;
      font-size: 27px;
      border: 2px solid #e5e5e5;
      border-radius: 60px;
      min-width: 150px;
      text-align: center;
      background-color: #fff;
      color: #666;
      margin: 0 10px 10px 0;
      &:hover {
        background: #1f1f22;
        color: #fff;
      }
    }
    .sel {
      background: #1f1f22;
      color: #fff;
    }
  }
}
/deep/.van-sticky {
  background: white;
}
</style>
